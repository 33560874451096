.w-md-editor{
	border-radius: 0;
	textarea.w-md-editor-text-input,.w-md-editor-content,.w-md-editor-area{
		border-radius: 0 !important;
		color: black !important;
		background-color: none !important;
		border: none !important;
		-webkit-text-fill-color: black;
		&:hover{
			color: black !important;
			border-radius: 0 !important;
			border: none;
		}
		&:focus{
			border: none !important;
			background-color: white !important;
			color: black !important;
		}
	}
	.wmde-markdown-color{
		color: black !important;
	}
	.w-md-editor-toolbar ul li button{
		width: 30px;
		height: 30px;
		svg{
			height: 16px;
			width: 16px;
		}
	}
	.w-md-editor-text{
		height: 100%;
	}
}