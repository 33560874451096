.ar.reports-container{
	.fr.sidebar {
		.sidebar{
			width: 300px;
	
			.divider{
				margin: 10px 20px 5px 19px;
				text-transform: uppercase;
				color: gray;
				font-weight: bold;
			}
		}
		.contents{
			margin-left: 300px;
		}
	}
}