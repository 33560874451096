.ar.event{
	display: flex;
	justify-content: center;
	padding: 20px;
	margin-bottom: 20px;
	
	&.centered{
		align-items: center;
	}

	img{
		width: 100px;
		height: 100px;
		border-radius: 8px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		border: 1px solid #dddddd;
	}
	.info{
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 0 10px;
		.name{
			font-size: 24px;
			font-weight: bold;
			max-width: 300px;
			line-height: 26px;
			margin-bottom: 5px;
		}
		.venue{
			font-size: 16px;
			color: gray;
		}
		.city{
			color: gray;
		}
	}

	@media screen and (max-width: 450px){
		margin-bottom: 0;
		padding-top: 0 !important;
	}
}

.ar.captcha{
	display: flex;
	justify-content: flex-end;
	margin: auto;
	margin-top: 10px;
	max-width: 500px;
	&.centered{
		justify-content: center;
	}
}

.g-recaptcha{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.fr.sidebar{
	overflow-y: scroll;
	.drawer{
		position: relative;
		z-index: 99;
		position: fixed;
		top: 0;
		&.active{
			position: fixed;
		}
	}
	
	.sidebar{
		position: fixed;
	}

	&.drawer-active{
		.contents{
			overflow: hidden;
		}
	}
}

.fr.field,.fr.fields{
	margin-bottom: 10px;
}
.fr.groupper{
	> .fr.toolbar.fitted{
		// background-color: red;
		box-shadow: none;
		border-left: 0;
		border-right: 0;
		border-radius: 0;

		margin-bottom: 15px;

		> .item{
			border-radius: 0;
		}
	}
	&:not(.fitted){
		> .fr.toolbar.fitted{
			margin-left: -20px;
			margin-right: -20px;
			margin-top: -21px;
		}
	}
}

.ui.modal table.fr.fitted.last.table{
	margin-bottom: -20px !important;
}

table.fr.table.basic{
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

table.fr.table td .button:not(.tiny) i{
	margin-right: 5px !important;
}

.fr.header.loading .sub.header{
	font-size: 14px;
}

.fr.segments{
	display: flex;
	width: 100%;
	justify-content: space-around;
	background-color: #fafafa;
	border-radius: 8px;

	$VALUE_BORDER: 2px solid #e0e0e0;
	.value{
		padding: 5px;
		flex: 1;
		text-align: center;
		border-right: 1px solid #e0e0e0;
		border-left: 1px solid #e0e0e0;
		border-color: #e0e0e0;
		font-weight: bold;
		cursor: pointer;
		border-top: $VALUE_BORDER;
		border-bottom: $VALUE_BORDER;

		&:hover{
			background-color: darken(#fafafa, 2%);
		}
		&.selected{
			background-color: #4d6adf;
			border-color: darken(#4d6adf, 3%) !important;
			color: white;
			&:hover{
				background-color: darken(#4d6adf, 10%);
			}
		}

		&:first-child{
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border-left: 2px solid #e0e0e0;
		}
		&:last-child{
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border-right: 2px solid #e0e0e0;
		}
	}
}