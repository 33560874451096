.ar.order-view-screen{
	table.fr.table{
		&.details{
			tbody td .fr.label{
				width: 120px;
			}
		}
		&.totals{
			thead th, tbody td{
				text-align: center;
			}
		}
		tbody td .fr.label{
			line-height: 14px;
		}
	}
	.fr.tickets.groupper{
		table td .fr.icon.button{
			font-size: 16px;
			line-height: 16px;
		}
	}
	.fr.toolbar.order{
		max-width: 600px;
		margin: auto;
	
		.ui.dropdown.item{
			padding-left: 5px;
			padding-right: 5px;
		}
		.item.green{
			color: green;
		}
	}
	
}

.ui.modal.refund-create{
	.ui.checkbox{
		display: block;
	}
	table.fr.table.basic{
		max-width: 200px;
		tbody tr{
			font-size: 16px;
			td{
				padding: 0;
				text-align: right;
				&:first-child{
					font-weight: bold;
					padding-right: 5px;
				}
			}
			&:last-child td{
				font-size: 20px;
				// color: red;
			}
		}
	}
}

.ui.modal.order-payment-concilia-manual{
	.fr.groupper > .head{
		font-size: 14px;
	}
	.fr.items{
		.meta{
			color: gray;
			font-size: 14px;
			line-height: 14px;
			&.small{
				font-size: 12px;
				line-height: 12px;
			}
		}
		.right{
			text-align: right;
		};
		.name{
			font-weight: bold;
		}
	}
}

table.fr.table{
	tbody td .fr.label{
		line-height: 14px;
	}
}

.fr.field{
	> .fr.input > .fr.popup-container{
		width: 100% !important;
	}
}