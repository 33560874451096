.fr.catalog.groupper{
	max-width: 600px;
	margin: auto;
	> .fr.small.button{
		display: block;
		margin: auto;
		margin-top: 10px;
		margin-bottom: -5px;
		max-width: 200px;
	}
	.fr.items{
		.item{
			img{
				width: 80px;
				height: 80px;
				max-width: 80px;
				max-height: 80px;
				border-radius: 4px;
				margin-right: 10px;
			}	
			.left .name{
				font-size: 20px;
				line-height: 20px;
				margin-bottom: 2px;
				font-weight: bold;
			}
		}
		.meta{
			color: gray;
		}
	}

	.top.filters{
		padding: 15px;
		border-top: 1px solid #e5e7eb;
	}
}