.fr.groupper.fitted > .section{
	padding: 15px;
}

.fr.horizontal.header{
	display: flex;
	align-items: center;
	> i.icon{
		font-size: 2em;
		margin-right: 10px !important;
		margin-bottom: 0 !important;
	}
	.content{
		text-align: left;
	}
	&.selectable{
		border-radius: 8px;
		padding: 5px;
		&:hover{
			background-color: rgba(0, 0, 0, 0.05);
			cursor: pointer;
		}
	}
}

table.fr.calendar.table{
	tr td:not(.today){
		font-weight: normal;
	}
}

.dropdown-action {
	border-top-right-radius: 0px!important;
	border-bottom-right-radius: 0px!important;
}

.fr.item.dragged{
	z-index: 1000;
	padding: 12px 15px;
	margin-bottom: 5px;
	border-radius: 8px;
	background-color: rgba(0,0,0,.03) !important;
}

table.fr.table{
	thead tr th .fr.button{
		text-transform: none;
	}
}