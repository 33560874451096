table.fr.orders.table{
	td .fr.label{
		width: 98px;
	}
	th.mobile, td.mobile{
		display: none;	
	}

	@media screen and (max-width: 781px){
		th.mobile, td.mobile{
			display: table-cell !important;
		}
	}
	@media screen and (max-width: 780px) {
		td.desktop, th.desktop{
			display: none;
		}
	}
	@media screen and (max-width: 550px) {
		td .fr.label{
			width: 75px;
			font-size: 12px;
			padding: 3px 8px;
		}
		td > a:not(.normal){
			padding: 0.75rem 0.5rem;
		}
	}
}