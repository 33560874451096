$AREMA_COLOR: #FD6A08;

.ar.menu{
	width: 100%;
	background-color: $AREMA_COLOR;
	display: flex;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	position: fixed;
	justify-content: left;
	top: 0;
	z-index: 100;

	.left{
		flex-grow: 100;
		display: flex;

		.icon{
			display: flex;
			justify-content: center;
			align-items: center;
			&.item{
				line-height: 1em;
				font-size: 16px;
				padding: 10px;
			}
			img{
				height: 35px;
				width: 50px;
				padding: 5px 0;
				margin: 0 10px;
				margin-top: -2px;
				object-fit: contain;
			}
		}
	}
	.right{
		display: flex;
	}
	.hamburger{
		height: 100%;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		.icon{
			margin: 0;
			margin-top: -2px;
			font-size: 28px;
			color: white;
		}
	}
	> .item, > * > .item{
		display: flex;
		align-items: center;
		padding: 10px 15px;
		color: white;
		text-decoration: none;
		&.active{
			background-color: lighten($AREMA_COLOR, 5);
		}
		&.icon{
			width: 40px;
			padding: 0;
			text-align: center;
			justify-content: center;
			i.icon{
				margin: 0;
			}
		}
		&.dropdown{
			.menu{
				min-width: 100%; 
				border-top-left-radius: 0; 
				border-top-right-radius: 0;
			}
			i.icon{
				font-size: 16px;
				line-height: 16px;
				margin-left: 5px;
				margin-right: 0;
			}
		}
	}
	.item:hover, .left .icon:hover, .left .hamburger:hover{
		background-color: lighten($AREMA_COLOR, 5);
		cursor: pointer;
	}
}

.ui.search.modal{
	&.catalog{
		.header{
			padding: 0;
			.fr.input{
				margin: 0;
				margin-bottom: 0 !important;
				border-radius: 0;
				width: 100%;
				input{
					width: 100%;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					font-size: 20px;
					padding-left: 50px;
				}
				i.icon{
					font-size: 20px;
					margin-right: 10px;
				}
			}
		}
	}
	.content{
		padding-top: 0;
		padding-bottom: 0;
		height: 500px;

		.fr.items{
			.item{
				font-size: 16px;
				i.icon{
					font-size: 20px;
					margin-right: 15px;
				}
				&.active{
					background-color: #DBDBDB;
					font-weight: bold;
					&:hover{
						background-color: darken(#DBDBDB, 2) !important;
					}
				}
			}
		}
	}
}

.ar.menu-sidebar{
	position: fixed;
	top: 40px;
	left: 0;
	width: 75%;
	max-width: 300px;
	background-color: #151717;
	height: calc(100vh - 40px);
	overflow-y: scroll;
	padding-top: 5px;
	z-index: 1000;
	.item{
		min-width: 280px;
		font-size: 24px;
		padding: 15px;
		cursor: pointer;
		display: block;
		color: white;
		border-bottom: 1px solid lighten(#151717, 5);
		&.dropdown{
			padding: 0;
			.content{
				display: flex;
				align-items: center;
				.text{
					flex-grow: 100;
					padding: 15px;
				}
				i.dropdown.icon{
					margin-right: 10px;
					padding-left: 10px;
					margin-right: 15px;
				}
			}
			.menu{
				.item{
					display: block;
					background-color: #1e1e24;
					padding: 15px;
					font-size: 18px;
					color: white;
					margin-bottom: 0;
				}
			}
		}
	}
}

@media screen and (min-width: 1000px){
	.ar.menu .hamburger{
		width: 0;
		.icon{
			display: none;
		}
	}
}

@media screen and (max-width: 1000px){
	.ar.menu > .left > .item:not(.hamburger){
		display: none;
	}
}