.ar.creator{
	max-width: 1000px;
	margin: auto;
	margin-bottom: -50px;
	> .contents{
		height: 650px;
		display: flex;
		> .fr.groupper.fr.groupper{
			margin: 0 !important;
			max-width: 300px !important;
			margin-right: 10px !important;
		}
		> .viewport{
			overflow: scroll;
			height: 650px;
			flex-grow: 100;
			display: flex;
			padding: 15px;
			justify-content: center;
			background-color: darken(white, 5%);
			border-radius: 12px;
			border: 2px solid #DDD;
		}
	}
}