$COLOR_NORMAL: #f0f0f0;
$COLOR_STAGE: #151717;
$COLOR_EMPTY: #FAFAFA;
$BORDER_RADIUS: 8px;

.fr.seatmap{
	&.tooled.tooled{
		padding-top: 0;
	}
	.row{
		.seat{
			width: 70px;
			min-width: 70px;
			max-width: 70px;
			height: 45px;
			min-height: 45px;
			max-height: 45px;
			background-color: darken($COLOR_NORMAL, 3%);
			&.empty{
				cursor: pointer;
				background-color: lighten($COLOR_NORMAL, 3%);
				&:hover{
					background-color: #e7e7e7;
				}
			}
			&.stage-normal{
				background-color: $COLOR_STAGE;
				color: white;
			}
			&.new{
				border-width: 1px;
				border-style: dashed;
				&.selected{
					border-style: solid;
					border-width: 2px;
					text-decoration: underline;
					text-decoration-thickness: 2px;
					text-decoration-color: brown;
				}
			}
			&.error{
				border: 2px dashed brown;
				background-color: rgba(165, 42, 42, 0.2);
				.number{
					color: brown;
				}
			}
		}
	}
}

.fr.map.groupper{
	margin: 0 10px;
	height: calc(100vh - 100px);

	.fr.toolbar{
		box-shadow: none;
		border-radius: 0;
		width: 100%;
		max-width: 100%;
		border-left: 0;
		border-right: 0;
		justify-content: center;
		> .item, > .ui.dropdown > .item{
			position: relative;
			width: auto;
			padding: 8px 15px;
			margin: 3px;
			min-width: 40px;
			font-weight: bold;
			background-color: darken(#F9FAFB, 5%);
			&:hover{
				background-color: darken(#F9FAFB, 8%);
			}
			&.black{
				background-color: #151717;
				color: white;
				&:hover{
					background-color: lighten(#151717, 10%);
				}
			}
			&.loading{
				color: transparent;
			}
		}
		.header{
			font-weight: bold;
		}
	}
	.fr.seatmap{
		height: calc(100% - 50px - 45px);
	}
}

.ui.popup{
	.fr.centered{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 100%;
		.fr.blank{
			width: 38px;
		}
	}
}

.ui.vertical.menu{
	border-radius: 12px;
	> a.item{
		&:last-child{
			border-radius: 0 0 12px 12px !important;
		}
		&:first-child{
			border-radius: 12px 12px 0 0 !important;
		}
	}

	&.context{
		.menu{
			width: 176px;
		}
		.menu.left{
			left: -175px !important;
			right: auto !important;
		}
	}
}

.fr.increment{
	display: flex;
	.fr.button{
		min-width: 40px;
		// width: 30px;
		padding: 0px;
		height: 40px;
		text-align: center;
		border-radius: 6px;
		> i{
			margin: 0;
			line-height: 38px;
		}
		&.minus{
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.plus{
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.amount{
		border: 1px solid #e5e7eb;
		padding: 10px;
		height: 40px;
		font-weight: bold;
		font-size: 14px;
		min-width: 60px;
		text-align: center;
		// background-color: rgba(0,0,0,0.05);
	}
}

.fr.labels {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: flex-start;

	.label {
		display: flex;
		justify-content: center;
		align-items: center;

		.color-status {
			margin: 5px;
			display: block;
			border: 1px solid black;
			width: 35px;
			height: 22px;	  
			border-radius: 6px;
		}
	}
}